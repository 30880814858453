const SEO = [
	{
	  page: "home",
	  description:
		"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
	  keywords: ["Chetan", "Chetan P Jain", "Chetan P"],
	},
  
	{
	  page: "about",
	  description:
		"I am a backend developer with expertise in Node.js. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies.",
	  keywords: ["Chetan", "Chetan P Jain", "Chetan P"],
	},
  
	{
	  page: "articles",
	  description:
		"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	  keywords: ["Chetan", "Chetan P Jain", "Chetan P"],
	},
  
	{
	  page: "projects",
	  description:
		"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to.",
	  keywords: ["Chetan", "Chetan P Jain", "Chetan P"],
	},
  
	{
	  page: "contact",
	  description:
		"If you're interested in collaborating on a project, feel free to reach out to me. I'm always open to new ideas and opportunities.",
	  keywords: ["Chetan", "Chetan P Jain", "Chetan P"],
	},
  ];
  
  export default SEO;
  