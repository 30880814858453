// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
	display: flex;
	padding-top: 25px;
	margin-top: 50px;
	padding-bottom: 25px;
	align-items: center;
}

.footer-links,
.footer-credits {
	flex-basis: 50%;
}

.footer-links {
	margin-left: -40px;
}

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: var(--secondary-color);
}

.footer-nav-link-list a:hover {
	color: var(--link-color);
}

.footer-credits-text {
	justify-content: flex-end;
	color: var(--tertiary-color);
	font-size: 14px;
	text-align: right;
}

@media (max-width: 600px) {
	.footer {
		flex-direction: column;
		height: 85px;
	}

	.footer-links {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.footer-nav-link-list {
		width: 100%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/common/styles/footer.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,iBAAiB;CACjB,gBAAgB;CAChB,oBAAoB;CACpB,mBAAmB;AACpB;;AAEA;;CAEC,eAAe;AAChB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,gBAAgB;CAChB,8BAA8B;CAC9B,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC,qBAAqB;CACrB,6BAA6B;AAC9B;;AAEA;CACC,wBAAwB;AACzB;;AAEA;CACC,yBAAyB;CACzB,4BAA4B;CAC5B,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC;EACC,sBAAsB;EACtB,YAAY;CACb;;CAEA;EACC,aAAa;EACb,WAAW;EACX,uBAAuB;CACxB;;CAEA;EACC,WAAW;CACZ;AACD","sourcesContent":["@import \"../../../data/styles.css\";\n\n.footer {\n\tdisplay: flex;\n\tpadding-top: 25px;\n\tmargin-top: 50px;\n\tpadding-bottom: 25px;\n\talign-items: center;\n}\n\n.footer-links,\n.footer-credits {\n\tflex-basis: 50%;\n}\n\n.footer-links {\n\tmargin-left: -40px;\n}\n\n.footer-nav-link-list {\n\tdisplay: flex;\n\tlist-style: none;\n\tjustify-content: space-between;\n\talign-items: center;\n}\n\n.footer-nav-link-item {\n\tfont-weight: bold;\n\tfont-size: 80%;\n}\n\n.footer-nav-link-list a {\n\ttext-decoration: none;\n\tcolor: var(--secondary-color);\n}\n\n.footer-nav-link-list a:hover {\n\tcolor: var(--link-color);\n}\n\n.footer-credits-text {\n\tjustify-content: flex-end;\n\tcolor: var(--tertiary-color);\n\tfont-size: 14px;\n\ttext-align: right;\n}\n\n@media (max-width: 600px) {\n\t.footer {\n\t\tflex-direction: column;\n\t\theight: 85px;\n\t}\n\n\t.footer-links {\n\t\tdisplay: flex;\n\t\twidth: 100%;\n\t\tjustify-content: center;\n\t}\n\n\t.footer-nav-link-list {\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
