const INFO = {
	main: {
		title: "Myfolio by Chetan",
		name: "Chetan P Jain",
		email: "chetanplrch@gmail.com",
		logo: "../coder.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/chetan-plrch",
		linkedin: "https://linkedin.com/in/chetan-p-jain",
		instagram: "https://instagram.com/chetanplrch",
		stackoverflow: "https://stackoverflow.com/users/11457924/chetan-jain",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Web developer, and finance enthusiast.",
		description:
		  "I am a developer by heart and a finance enthusiast by passion. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	  },
	
	about: {
		title: "I’m Chetan P Jain. I live in New York, where I design the future.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of developers.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Lowes - Baymax",
			description: [
				"Designed Baymax (scalable internal A/B testing and feature flagging platform) to replace cost-prohibitive software",
				"Developed client and server SDK for performing experimentation on Lowes.com",
				"Boosted execution speed of SDKs by 90% and reduced overall latency to less than 50ms by accomplishing a Forward caching mechanism based on distributed Redis cache and by leveraging Kafka to keep cache consistent for scalability",
				"Lowered data footprint by applying a serverless model deploying Murmurhash algorithm to optimize application and to service over 15.3M requests daily",
				"Achieved zero downtime while migrating Baymax from reverse proxy SSO to OIDC using Node.js, React.js, Java, and GCP",
				"Enhanced consumer experience and advanced platform capability and reach by building and accomplishing an end-to-end functionality of Redirect, Cookie-based, JSON-type A/B test, along with feature flags",
				"Empowered teams through product demos and provided swift support for critical heap overflow issues",
				"Collaborated with team lead on engineering activity history feature with ability to rollback from audit trail",
				"Devised awareness of product’s current and future capabilities among internal teams and senior management by leading product and feature launch demos in company’s product fairs and hands-on sessions",
				"Migrated data from Couchbase to MongoDB for cost optimization operating divide and conquer strategy",
				"Collaborated with UX/UI designer to make product intuitive by engaging with users to receive product feedback"
			  ],
			logo: "/lowes.png",
			linkText: "View Project",
			link: "https://lowes.com",
		},

		{
			title: "Black Lotus - Web and Mobile application",
			description: [
				"Improved user experience and made company profitable by building strategy, design, and exhaustive user flows, and implementing end-to-end In-App purchases to support subscriptions of premium content",
				"Enhanced user engagement by 480% by architecting high-level and low-level systems for commenting on RAK’s (random acts of kindness) stories",
				"Expanded user base by 183% by conceptualizing and constructing guest login for black lotus app to reduce user friction"
			  ],
			logo: "./blacklotus.jpeg",
			linkText: "View Project",
			link: "https://play.google.com/store/apps/details?id=com.rt.pinprickeffect.meditation&hl=en_US&gl=US&pli=1",
		},
		{
			title: "Yourstory - Creator Studio, My Account, Yourstory.com",
			description: [
				"Created a secure federated authentication, single sign-on system handling traffic of 1.1 million requests per day and is authentication layer for all organization’s products",
				"Improved usability of platform by integrating content scheduling leveraging AWS lambda and its end-to-end user flow on content management system (CMS)",
				"Strengthened user convenience on CMS by bringing Google & MS Document to HTML conversion native to Quill.js",
				"Developed CMS editor section with pair programming to shorten release cycle",
				"Constructed real-time resource locking and unlocking with ability to manage access in real-time utilizing WebSocket",
				"Advanced user engagement by 25% on YourStory.com by integrating Mozilla's Coral Talk (an open-source commenting system) and setup up its CI-CD, operating Docker and AWS CodeDeploy",
				"Deployed scalable and dynamic site map generation AWS lambda for SEO of updated content"
			  ],
			logo: "/yourstory.svg",
			linkText: "View Project",
			link: "https://yourstory.com/",
		}
	],
};

export default INFO;
