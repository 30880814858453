// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-projects-container {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	padding-top: 30px;
	padding-bottom: 30px;
	margin-left: -35px;
	margin-right: -35px;
}

.all-projects-project {
	width: 100%;
	box-sizing: border-box;
	padding: 10px;
	overflow: hidden;
}

@media (max-width: 600px) {
	.all-projects-project {
		width: calc(100% / 2);
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/projects/styles/allProjects.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,eAAe;CACf,oBAAoB;CACpB,iBAAiB;CACjB,oBAAoB;CACpB,kBAAkB;CAClB,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,sBAAsB;CACtB,aAAa;CACb,gBAAgB;AACjB;;AAEA;CACC;EACC,qBAAqB;CACtB;AACD","sourcesContent":[".all-projects-container {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\talign-items: stretch;\n\tpadding-top: 30px;\n\tpadding-bottom: 30px;\n\tmargin-left: -35px;\n\tmargin-right: -35px;\n}\n\n.all-projects-project {\n\twidth: 100%;\n\tbox-sizing: border-box;\n\tpadding: 10px;\n\toverflow: hidden;\n}\n\n@media (max-width: 600px) {\n\t.all-projects-project {\n\t\twidth: calc(100% / 2);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
