// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
}

.project a {
	text-decoration: none;
}

.project:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;
}

.project-logo {
	width: 80px;
}

.project-logo img {
	width: 100%;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-weight: 700;
}

.project-description {
	color: var(--secondary-color);
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text {
	padding-left: 20px;
	font-weight: 700;
}

@media (max-width: 600px) {
	.project {
		height: 300px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/projects/styles/project.css"],"names":[],"mappings":"AAEA;CACC,sBAAsB;CACtB,mBAAmB;CACnB,YAAY;CACZ,YAAY;AACb;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,mBAAmB;CACnB,UAAU;CACV,6CAA6C;AAC9C;;AAEA;CACC,wBAAwB;CACxB,kCAAkC;AACnC;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,kCAAkC;CAClC,2BAA2B;CAC3B,eAAe;CACf,iBAAiB;CACjB,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,6BAA6B;CAC7B,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;CACjB,eAAe;AAChB;;AAEA;CACC,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC;EACC,aAAa;CACd;AACD","sourcesContent":["@import \"../../../data/styles.css\";\n\n.project {\n\tmix-blend-mode: normal;\n\tborder-radius: 20px;\n\topacity: 0.8;\n\theight: 100%;\n}\n\n.project a {\n\ttext-decoration: none;\n}\n\n.project:hover {\n\tbackground: #fafafa;\n\topacity: 1;\n\ttransition: background-color 0.3s ease-in-out;\n}\n\n.project:hover .project-link {\n\tcolor: var(--link-color);\n\ttransition: color 0.3s ease-in-out;\n}\n\n.project-container {\n\tpadding: 30px;\n}\n\n.project-logo {\n\twidth: 80px;\n}\n\n.project-logo img {\n\twidth: 100%;\n}\n\n.project-title {\n\tfont-family: var(--secondary-font);\n\tcolor: var(--primary-color);\n\tfont-size: 16px;\n\tpadding-top: 15px;\n\tmargin-bottom: 1em;\n\tfont-weight: 700;\n}\n\n.project-description {\n\tcolor: var(--secondary-color);\n}\n\n.project-link {\n\tdisplay: flex;\n\talign-items: center;\n\tcolor: var(--secondary-color);\n\tfont-size: 12px;\n\tpadding-top: 20px;\n}\n\n.project-link-icon {\n\tpadding-left: 5px;\n\tfont-size: 13px;\n}\n\n.project-link-text {\n\tpadding-left: 20px;\n\tfont-weight: 700;\n}\n\n@media (max-width: 600px) {\n\t.project {\n\t\theight: 300px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
