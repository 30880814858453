// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
	border-radius: 50%;
	display: block;
	margin: 0;
	padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/common/styles/logo.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,cAAc;CACd,SAAS;CACT,UAAU;AACX","sourcesContent":[".logo {\n\tborder-radius: 50%;\n\tdisplay: block;\n\tmargin: 0;\n\tpadding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
