import React from "react";

function article_1() {
  return {
    date: "7 May 2023",
    title: "Introduction to Cloud Computing",
    description:
      "Learn about the fundamentals of cloud computing and its benefits in modern businesses.",
    keywords: ["Introduction to Cloud Computing", "Chetan", "Chetan P Jain", "Chetan P"],
    style: `
        .article-content {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .randImage {
          align-self: center;
          outline: 2px solid red;
        }
        `,
    body: (
      <React.Fragment>
        <div className="article-content">
          <div className="paragraph">Content of article 1</div>
          <img
            src="https://picsum.photos/200/300"
            alt="random"
            className="randImage"
          />
        </div>
      </React.Fragment>
    ),
  };
}

function article_2() {
  return {
    date: "7 May 2023",
    title: "The Role of Artificial Intelligence in Modern Industries",
    description:
      "Explore the impact of AI in various industries and how it's shaping the future of technology.",
    style: ``,
    keywords: ["The Role of Artificial Intelligence", "Chetan", "Chetan P Jain", "Chetan P"],
    body: (
      <React.Fragment>
        <h1>Content of article 2</h1>
      </React.Fragment>
    ),
  };
}

const myArticles = [article_1, article_2];

export default myArticles;
