// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-container {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 3vh;
	z-index: 999;
}

.nav-background {
	width: 380px;
	height: 40px;
	padding-left: 0%;
	padding-right: 0%;
	background: #fff;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
	border-radius: 40px;
}

.nav-list {
	display: flex;
	justify-content: space-between;
	list-style: none;
	align-items: center;
	margin-left: -16px;
	margin-right: 20px;
	margin-top: 11px;
}

.nav-item {
	font-weight: bold;
	font-size: 80%;
}

.nav-item.active a {
	color: var(--link-color) !important;
}

.nav-item a {
	text-decoration: none;
	color: var(--primary-color);
}

.nav-item a:hover {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

@media (max-width: 600px) {
	.navbar {
		margin-left: 25%;
		margin-right: 25%;
		width: 80%;
		font-size: 80%;
	}

	.nav-background {
		width: 80%;
		height: 40px;
	}

	.nav-item {
		font-weight: bold;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/common/styles/navBar.css"],"names":[],"mappings":"AAEA;CACC,SAAS;CACT,aAAa;CACb,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,eAAe;CACf,QAAQ;CACR,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,YAAY;CACZ,gBAAgB;CAChB,iBAAiB;CACjB,gBAAgB;CAChB,2CAA2C;CAC3C,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,gBAAgB;CAChB,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;CACjB,cAAc;AACf;;AAEA;CACC,mCAAmC;AACpC;;AAEA;CACC,qBAAqB;CACrB,2BAA2B;AAC5B;;AAEA;CACC,wBAAwB;CACxB,kCAAkC;AACnC;;AAEA;CACC;EACC,gBAAgB;EAChB,iBAAiB;EACjB,UAAU;EACV,cAAc;CACf;;CAEA;EACC,UAAU;EACV,YAAY;CACb;;CAEA;EACC,iBAAiB;CAClB;AACD","sourcesContent":["@import \"../../../data/styles.css\";\n\n.nav-container {\n\tmargin: 0;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n}\n\n.navbar {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tposition: fixed;\n\ttop: 3vh;\n\tz-index: 999;\n}\n\n.nav-background {\n\twidth: 380px;\n\theight: 40px;\n\tpadding-left: 0%;\n\tpadding-right: 0%;\n\tbackground: #fff;\n\tbox-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);\n\tborder-radius: 40px;\n}\n\n.nav-list {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tlist-style: none;\n\talign-items: center;\n\tmargin-left: -16px;\n\tmargin-right: 20px;\n\tmargin-top: 11px;\n}\n\n.nav-item {\n\tfont-weight: bold;\n\tfont-size: 80%;\n}\n\n.nav-item.active a {\n\tcolor: var(--link-color) !important;\n}\n\n.nav-item a {\n\ttext-decoration: none;\n\tcolor: var(--primary-color);\n}\n\n.nav-item a:hover {\n\tcolor: var(--link-color);\n\ttransition: color 0.3s ease-in-out;\n}\n\n@media (max-width: 600px) {\n\t.navbar {\n\t\tmargin-left: 25%;\n\t\tmargin-right: 25%;\n\t\twidth: 80%;\n\t\tfont-size: 80%;\n\t}\n\n\t.nav-background {\n\t\twidth: 80%;\n\t\theight: 40px;\n\t}\n\n\t.nav-item {\n\t\tfont-weight: bold;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
