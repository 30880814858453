// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homepage-article {
	width: 90%;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
}

.homepage-article:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.homepage-article-content {
	padding: 30px;
}

.homepage-article-date {
	opacity: 1;
	color: var(--tertiary-color);
	font-size: 12px;
	margin-bottom: 1em;
}

.homepage-article-title {
	opacity: 1;
	color: var(--primary-color);
	font-size: 16px;
	margin-bottom: 1em;
	font-weight: 700;
}

.homepage-article-description {
	opacity: 1;
	color: var(--secondary-color);
	font-size: 12px;
	line-height: 1.5;
}

.homepage-article-link {
	opacity: 1;
	padding-top: 20px;
	font-size: 15px;
	font-weight: 700;
}

.homepage-article-link a {
	color: var(--link-color);
	text-decoration: none;
}

@media (max-width: 600px) {
	.homepage-article {
		width: 100%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/homepage/styles/article.css"],"names":[],"mappings":"AAEA;CACC,UAAU;CACV,sBAAsB;CACtB,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,mBAAmB;CACnB,UAAU;CACV,6CAA6C;AAC9C;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,UAAU;CACV,4BAA4B;CAC5B,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,UAAU;CACV,2BAA2B;CAC3B,eAAe;CACf,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,6BAA6B;CAC7B,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,UAAU;CACV,iBAAiB;CACjB,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,wBAAwB;CACxB,qBAAqB;AACtB;;AAEA;CACC;EACC,WAAW;CACZ;AACD","sourcesContent":["@import \"../../../data/styles.css\";\n\n.homepage-article {\n\twidth: 90%;\n\tmix-blend-mode: normal;\n\tborder-radius: 20px;\n\topacity: 0.8;\n}\n\n.homepage-article:hover {\n\tbackground: #fafafa;\n\topacity: 1;\n\ttransition: background-color 0.3s ease-in-out;\n}\n\n.homepage-article-content {\n\tpadding: 30px;\n}\n\n.homepage-article-date {\n\topacity: 1;\n\tcolor: var(--tertiary-color);\n\tfont-size: 12px;\n\tmargin-bottom: 1em;\n}\n\n.homepage-article-title {\n\topacity: 1;\n\tcolor: var(--primary-color);\n\tfont-size: 16px;\n\tmargin-bottom: 1em;\n\tfont-weight: 700;\n}\n\n.homepage-article-description {\n\topacity: 1;\n\tcolor: var(--secondary-color);\n\tfont-size: 12px;\n\tline-height: 1.5;\n}\n\n.homepage-article-link {\n\topacity: 1;\n\tpadding-top: 20px;\n\tfont-size: 15px;\n\tfont-weight: 700;\n}\n\n.homepage-article-link a {\n\tcolor: var(--link-color);\n\ttext-decoration: none;\n}\n\n@media (max-width: 600px) {\n\t.homepage-article {\n\t\twidth: 100%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
