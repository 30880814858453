// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article {
	display: flex;
}

.article a {
	text-decoration: none;
}

.article-left-side {
	min-width: 20%;
	max-width: 20%;
}

.article-right-side {
	flex-basis: 80%;
	min-width: 700px;
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	padding: 30px;
}

.article-right-side:hover {
	background: #fafafa;
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.article-date {
	padding-top: 30px;
	font-size: 15px;
	color: var(--tertiary-color);
}

.article-title {
	color: #000000;
	font-size: 16px;
	font-weight: 600;
}

.article-description {
	padding-top: 10px;
	font-size: 12px;
	color: var(--secondary-color);
	font-weight: 480;
	line-height: 25px;
}

.article-link {
	padding-top: 10px;
	font-size: 14px;
	color: var(--link-color);
	font-weight: 700;
}

@media (max-width: 1024px) {
	.article-left-side {
		min-width: 10%;
		max-width: 10%;
	}

	.article-right-side {
		flex-basis: 90%;
		min-width: 85%;
		max-width: 85%;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/articles/style/article.css"],"names":[],"mappings":"AAEA;CACC,aAAa;AACd;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,cAAc;CACd,cAAc;AACf;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,sBAAsB;CACtB,mBAAmB;CACnB,YAAY;CACZ,aAAa;AACd;;AAEA;CACC,mBAAmB;CACnB,UAAU;CACV,6CAA6C;AAC9C;;AAEA;CACC,iBAAiB;CACjB,eAAe;CACf,4BAA4B;AAC7B;;AAEA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;CACjB,eAAe;CACf,6BAA6B;CAC7B,gBAAgB;CAChB,iBAAiB;AAClB;;AAEA;CACC,iBAAiB;CACjB,eAAe;CACf,wBAAwB;CACxB,gBAAgB;AACjB;;AAEA;CACC;EACC,cAAc;EACd,cAAc;CACf;;CAEA;EACC,eAAe;EACf,cAAc;EACd,cAAc;CACf;AACD","sourcesContent":["@import \"../../../data/styles.css\";\n\n.article {\n\tdisplay: flex;\n}\n\n.article a {\n\ttext-decoration: none;\n}\n\n.article-left-side {\n\tmin-width: 20%;\n\tmax-width: 20%;\n}\n\n.article-right-side {\n\tflex-basis: 80%;\n\tmin-width: 700px;\n\tmix-blend-mode: normal;\n\tborder-radius: 20px;\n\topacity: 0.8;\n\tpadding: 30px;\n}\n\n.article-right-side:hover {\n\tbackground: #fafafa;\n\topacity: 1;\n\ttransition: background-color 0.3s ease-in-out;\n}\n\n.article-date {\n\tpadding-top: 30px;\n\tfont-size: 15px;\n\tcolor: var(--tertiary-color);\n}\n\n.article-title {\n\tcolor: #000000;\n\tfont-size: 16px;\n\tfont-weight: 600;\n}\n\n.article-description {\n\tpadding-top: 10px;\n\tfont-size: 12px;\n\tcolor: var(--secondary-color);\n\tfont-weight: 480;\n\tline-height: 25px;\n}\n\n.article-link {\n\tpadding-top: 10px;\n\tfont-size: 14px;\n\tcolor: var(--link-color);\n\tfont-weight: 700;\n}\n\n@media (max-width: 1024px) {\n\t.article-left-side {\n\t\tmin-width: 10%;\n\t\tmax-width: 10%;\n\t}\n\n\t.article-right-side {\n\t\tflex-basis: 90%;\n\t\tmin-width: 85%;\n\t\tmax-width: 85%;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
