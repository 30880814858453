// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/styles/projects.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,qBAAqB;CACrB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,kBAAkB;CAClB,4CAA4C;CAC5C,QAAQ;CACR,YAAY;AACb;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC;EACC,iBAAiB;CAClB;;CAEA;EACC,qBAAqB;CACtB;AACD","sourcesContent":[".projects-logo-container {\n\tdisplay: flex;\n\tjustify-content: left;\n\tpadding-top: 25px;\n}\n\n.projects-logo {\n\tdisplay: flex;\n\tposition: fixed;\n\tborder: 1px solid white;\n\tborder-radius: 50%;\n\tbox-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n\ttop: 4vh;\n\tz-index: 999;\n}\n\n.projects-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n\tmargin: 0;\n\tpadding-top: 120px;\n}\n\n@media (max-width: 600px) {\n\t.projects-container {\n\t\tpadding-top: 60px;\n\t}\n\n\t.projects-project {\n\t\twidth: calc(100% / 2);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
