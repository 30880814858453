// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	padding-bottom: 40px;
	width: 400px;
}

.work-image {
	width: 70px;
	border-radius: 5px;
	outline: 6px solid white;
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 35px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 105px;
}

.work-duration {
	position: absolute;
    width: 350px;
    font-size: 12px;
    padding-top: 20px;
	padding-left: 100px;
    text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/components/homepage/styles/works.css"],"names":[],"mappings":"AAEA;CACC,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,oBAAoB;CACpB,YAAY;AACb;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,wBAAwB;AACzB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,kBAAkB;CAClB,gBAAgB;CAChB,6BAA6B;AAC9B;;AAEA;CACC,kBAAkB;CAClB,eAAe;CACf,6BAA6B;CAC7B,iBAAiB;CACjB,mBAAmB;AACpB;;AAEA;CACC,kBAAkB;IACf,YAAY;IACZ,eAAe;IACf,iBAAiB;CACpB,mBAAmB;IAChB,iBAAiB;AACrB","sourcesContent":["@import \"../../../data/styles.css\";\n\n.works-body {\n\tmargin-bottom: 10px;\n}\n\n.work {\n\tdisplay: flex;\n\tpadding-bottom: 40px;\n\twidth: 400px;\n}\n\n.work-image {\n\twidth: 70px;\n\tborder-radius: 5px;\n\toutline: 6px solid white;\n}\n\n.work-title {\n\tfont-size: 15px;\n\tfont-weight: 700;\n\tpadding-left: 35px;\n\tmargin-top: -3px;\n\tcolor: var(--secondary-color);\n}\n\n.work-subtitle {\n\tposition: absolute;\n\tfont-size: 12px;\n\tcolor: var(--secondary-color);\n\tpadding-top: 22px;\n\tpadding-left: 105px;\n}\n\n.work-duration {\n\tposition: absolute;\n    width: 350px;\n    font-size: 12px;\n    padding-top: 20px;\n\tpadding-left: 100px;\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
