// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.articles-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.articles-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.articles-main-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.articles-container {
	display: flex;
	flex-direction: column;
}

.articles-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
	display: flex;
	flex-direction: column;
}

.articles-article {
	padding-top: 0px;
	padding-left: 35px;
	padding-bottom: 20px;
	border-left: 2px solid #f4f4f5;
	width: 100%;
}

@media (max-width: 1024px) {
	.articles-title {
		width: 100% !important;
	}

	.articles-subtitle {
		width: 100% !important;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/styles/articles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,qBAAqB;CACrB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,kBAAkB;CAClB,4CAA4C;CAC5C,QAAQ;AACT;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,YAAY;CACZ,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,iBAAiB;CACjB,oBAAoB;CACpB,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,gBAAgB;CAChB,kBAAkB;CAClB,oBAAoB;CACpB,8BAA8B;CAC9B,WAAW;AACZ;;AAEA;CACC;EACC,sBAAsB;CACvB;;CAEA;EACC,sBAAsB;CACvB;AACD","sourcesContent":[".articles-logo-container {\n\tdisplay: flex;\n\tjustify-content: left;\n\tpadding-top: 25px;\n}\n\n.articles-logo {\n\tdisplay: flex;\n\tposition: fixed;\n\tborder: 1px solid white;\n\tborder-radius: 50%;\n\tbox-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n\ttop: 4vh;\n}\n\n.articles-main-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: 100%;\n\tmargin: 0;\n\tpadding-top: 120px;\n}\n\n.articles-container {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.articles-wrapper {\n\tpadding-top: 50px;\n\tpadding-bottom: 50px;\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.articles-article {\n\tpadding-top: 0px;\n\tpadding-left: 35px;\n\tpadding-bottom: 20px;\n\tborder-left: 2px solid #f4f4f5;\n\twidth: 100%;\n}\n\n@media (max-width: 1024px) {\n\t.articles-title {\n\t\twidth: 100% !important;\n\t}\n\n\t.articles-subtitle {\n\t\twidth: 100% !important;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
