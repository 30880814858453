import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="./lowes.png"
								alt="lowes"
								className="work-image"
							/>
							<div className="work-title">Lowe's Companies Inc</div>
							<div className="work-subtitle">
								Senior Software Engineer
							</div>
							<div className="work-duration">Aug 2020 - Dec 2022</div>
						</div>

						<div className="work">
							<img
								src="./blacklotus.jpeg"
								alt="black lotus"
								className="work-image"
							/>
							<div className="work-title">Black Lotus Technologies Pvt Ltd</div>
							<div className="work-subtitle">
								Software Engineer
							</div>
							<div className="work-duration">June 2019 - July 2020</div>
						</div>
						<div className="work">
							<img
								src="./yourstory.svg"
								alt="Yourstory Media"
								className="work-image"
							/>
							<div className="work-title">Yourstory</div>
							<div className="work-subtitle">
								Full Stack Developer
							</div>
							<div className="work-duration">Feb 2018 - May 2019</div>
						</div>
					</div>

					
				}
			/>
		</div>
	);
};

export default Works;
