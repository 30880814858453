// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
`, "",{"version":3,"sources":["webpack://./src/data/styles.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,wBAAwB;CACxB,0BAA0B;CAC1B,yBAAyB;CACzB,2BAA2B;CAC3B,qBAAqB;CACrB,2BAA2B;;CAE3B,0BAA0B;CAC1B,mCAAmC;CACnC,sCAAsC;CACtC,0BAA0B;AAC3B","sourcesContent":[":root {\n\t/* ------- colors ------- */\n\t--primary-color: #27272a;\n\t--secondary-color: #65656d;\n\t--tertiary-color: #acacb4;\n\t--quaternary-color: #e4e4e7;\n\t--link-color: #14b8a6;\n\t/* ---------------------- */\n\n\t/* ------- fonts ------- */\n\t--primary-font: \"Heebo\", sans-serif;\n\t--secondary-font: \"Roboto\", sans-serif;\n\t/* --------------------- */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
