// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-subtitle {
	width: 100% !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}


.socials-container {
	display: flex;
	flex-direction: column;
	margin-top: 80px;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/styles/contact.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,qBAAqB;CACrB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,kBAAkB;CAClB,4CAA4C;CAC5C,QAAQ;AACT;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,6BAA6B;CAC7B,iBAAiB;AAClB;;;AAGA;CACC,aAAa;CACb,sBAAsB;CACtB,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,2BAA2B;CAC3B,mBAAmB;AACpB","sourcesContent":[".contact-logo-container {\n\tdisplay: flex;\n\tjustify-content: left;\n\tpadding-top: 25px;\n}\n\n.contact-logo {\n\tdisplay: flex;\n\tposition: fixed;\n\tborder: 1px solid white;\n\tborder-radius: 50%;\n\tbox-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n\ttop: 4vh;\n}\n\n.contact-subtitle {\n\twidth: 100% !important;\n}\n\n.contact-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: space-around;\n\tmargin-top: 120px;\n}\n\n\n.socials-container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tmargin-top: 80px;\n}\n\n.contact-socials {\n\tdisplay: flex;\n\tjustify-content: flex-start;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
