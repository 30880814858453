// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../data/styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read-article-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.read-article-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.read-article-container {
	display: flex;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.read-article-back {
	padding-top: 68px;
	padding-right: 50px;
	width: 40px;
	height: 40px;
}

.read-article-back-button {
	width: 50px;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.read-article-wrapper {
	width: 80%;
	padding-top: 30px;
}

.read-article-date-container {
	border-left: 2px solid var(--quaternary-color);
	color: var(--tertiary-color);
	font-size: 16 px;
	align-items: center;
	height: 22px;
}

.read-article-date {
	font-family: var(--primary-font);
	padding-left: 15px;
}

.read-article-title {
	padding-top: 50px;
	padding-bottom: 50px;
	width: 100% !important;
}

.read-article-body {
	padding-bottom: 50px;
	font-size: 16px;
	line-height: 30px;
	font-family: system-ui;
	color: #52525b;
}
`, "",{"version":3,"sources":["webpack://./src/pages/styles/readArticle.css"],"names":[],"mappings":"AAEA;CACC,aAAa;CACb,qBAAqB;CACrB,iBAAiB;AAClB;;AAEA;CACC,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,kBAAkB;CAClB,4CAA4C;CAC5C,QAAQ;AACT;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,WAAW;CACX,YAAY;AACb;;AAEA;CACC,WAAW;CACX,kBAAkB;CAClB,4CAA4C;AAC7C;;AAEA;CACC,UAAU;CACV,iBAAiB;AAClB;;AAEA;CACC,8CAA8C;CAC9C,4BAA4B;CAC5B,gBAAgB;CAChB,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,gCAAgC;CAChC,kBAAkB;AACnB;;AAEA;CACC,iBAAiB;CACjB,oBAAoB;CACpB,sBAAsB;AACvB;;AAEA;CACC,oBAAoB;CACpB,eAAe;CACf,iBAAiB;CACjB,sBAAsB;CACtB,cAAc;AACf","sourcesContent":["@import \"../../data/styles.css\";\n\n.read-article-logo-container {\n\tdisplay: flex;\n\tjustify-content: left;\n\tpadding-top: 25px;\n}\n\n.read-article-logo {\n\tdisplay: flex;\n\tposition: fixed;\n\tborder: 1px solid white;\n\tborder-radius: 50%;\n\tbox-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n\ttop: 4vh;\n}\n\n.read-article-container {\n\tdisplay: flex;\n\theight: 100%;\n\tmargin: 0;\n\tpadding-top: 120px;\n}\n\n.read-article-back {\n\tpadding-top: 68px;\n\tpadding-right: 50px;\n\twidth: 40px;\n\theight: 40px;\n}\n\n.read-article-back-button {\n\twidth: 50px;\n\tborder-radius: 50%;\n\tbox-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);\n}\n\n.read-article-wrapper {\n\twidth: 80%;\n\tpadding-top: 30px;\n}\n\n.read-article-date-container {\n\tborder-left: 2px solid var(--quaternary-color);\n\tcolor: var(--tertiary-color);\n\tfont-size: 16 px;\n\talign-items: center;\n\theight: 22px;\n}\n\n.read-article-date {\n\tfont-family: var(--primary-font);\n\tpadding-left: 15px;\n}\n\n.read-article-title {\n\tpadding-top: 50px;\n\tpadding-bottom: 50px;\n\twidth: 100% !important;\n}\n\n.read-article-body {\n\tpadding-bottom: 50px;\n\tfont-size: 16px;\n\tline-height: 30px;\n\tfont-family: system-ui;\n\tcolor: #52525b;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
